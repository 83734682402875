import { Box, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import useAnalytics from "hooks/use-analytics";
import {
  canManageAutomations,
  canManageIntegrations,
  canManageRolePermissions,
} from "util/permissions";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "components/shared/topbar/TopBar";
import BackIconButton from "components/shared/BackButton";
import {
  SettingsContextProvider,
  useSettingsContext,
} from "hooks/use-settings-context";
import { useAppSelector } from "redux/hooks";
import DesktopUserSettings from "./desktop";
import MobileUserSettings from "./mobile";

export enum SettingsRoutes {
  ACCOUNT_OVERVIEW = "account_overview",
  INTEGRATION_HUB = "integration_hub",
  CHANNELS = "channels",
  TEMPLATES = "templates",
  FILES = "files",
  AUTOMATIONS = "automations",
  TEAM_MEMBERS = "teammates",
  PERMISSIONS = "permissions",
}

const UserSettings = () => {
  const { actionButton, shouldDisplayBackButton } = useSettingsContext();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState<string>(
    location.pathname
      .split("/")
      .filter((p) => !!p)
      .reverse()[0]
  );

  useEffect(() => {
    setCurrentRoute(
      location.pathname
        .split("/")
        .filter((p) => !!p)
        .reverse()[0]
    );
  }, [location.pathname]);

  useEffect(() => {
    const isIntegrationsRoute =
      currentRoute === SettingsRoutes.INTEGRATION_HUB ||
      currentRoute === SettingsRoutes.CHANNELS;
    const isAutomationsRoute = currentRoute === SettingsRoutes.AUTOMATIONS;
    const isIntegrationsAllowed = canManageIntegrations(
      merchant.id,
      currentAgent!
    );
    const isPermissionsRoute = currentRoute === SettingsRoutes.PERMISSIONS;
    const isPermissionsAllowed = canManageRolePermissions(
      merchant.id,
      currentAgent!
    );
    const isAutomationsAllowed = canManageAutomations(
      merchant.id,
      currentAgent!
    );

    const shouldRedirect =
      (isIntegrationsRoute && !isIntegrationsAllowed) ||
      (isAutomationsRoute && !isAutomationsAllowed) ||
      (isPermissionsRoute && !isPermissionsAllowed);

    if (!isBaseSize && shouldRedirect) {
      navigate(`/${merchant.id}/settings`);
    }
  }, [currentRoute, merchant]);

  const [loadingTracked, setLoadingTracked] = useState<boolean>(false);

  useEffect(() => {
    const shouldSendAnalyticsRequest =
      currentAgent && merchant && !loadingTracked;

    if (isBaseSize || !shouldSendAnalyticsRequest) {
      return;
    }

    track(`open_${currentRoute}_settings`);
    setLoadingTracked(true);
  }, [currentAgent, merchant, loadingTracked]);

  return (
    <>
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        overflow="hidden"
        {...(isBaseSize
          ? {}
          : {
              bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
            })}
      >
        {isBaseSize ? (
          <>
            <Topbar
              isFlex={false}
              leftChildrenMobile={
                <BackIconButton
                  displayBackIcon={shouldDisplayBackButton}
                  onBackIconClick={() => {
                    navigate(".");
                  }}
                />
              }
              leftChildren={
                <BackIconButton
                  displayBackIcon={shouldDisplayBackButton}
                  onBackIconClick={() => {
                    navigate(".");
                  }}
                />
              }
              rightChildrenMobile={actionButton}
            />
            <MobileUserSettings />
          </>
        ) : (
          <DesktopUserSettings />
        )}
      </Box>
    </>
  );
};

const UserSettingsWithProvider = () => {
  return (
    <SettingsContextProvider>
      <UserSettings />
    </SettingsContextProvider>
  );
};

export default UserSettingsWithProvider;
