import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import Merchant from "entities/domain/admin/merchants/admin-merchant-domain";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { adminContactChannelTransformerFromDtoToDomain } from "entities/transformers/admin-contact-channel-transformer";
import {
  adminMerchantTransformerFromDtoToDomain,
  bankAccountTransformFromDtoToDomain,
} from "entities/transformers/admin-merchant-transformer";
import { ChannelsDto } from "entities/dto/ChannelsDTO";
import {
  AdminMerchantDto,
  BankAccountType,
  BankDetailsType,
} from "entities/dto/MerchantDTO";
import { NewAgentDTO } from "entities/dto/NewAgentDTO";
import { newAgentTransformFromDtoToDomain } from "entities/transformers/new-agent-transformer";
import {
  numberOfAllAgentsPerLoad,
  numberOfAllMerchantsPerLoad,
} from "util/constants";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import MerchantTierLimitsDomain from "entities/domain/admin/merchants/merchant-tier-limits-domain";
import { TierType } from "entities/domain/admin/merchants/merchant-domain";
import AdminKeyloopAccountDomain from "entities/domain/admin/merchants/admin-keyloop-account-domain";
import { AdminKeyloopAccountDTO } from "entities/dto/AdminKeyloopAccountDTO";
import BankAccountDomain from "entities/domain/admin/merchants/bank-account-domain";
import { adminKeyloopAccountTransformFromDtoToDomain } from "entities/transformers/admin-keyloop-account-transformer";
import { RequestType } from "./request-type";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  postRequest,
  putRequest,
  request,
} from "../util/methods";

export interface AssignAgentToMerchantPayload {
  agentIds: number[];
  merchantsRoles: {
    [key: number]: number;
  };
}

export interface DisconnectAgentFromMerchantsPayload {
  agentId: number;
  merchantIds: number[];
}

export interface CreateAgentPayload {
  merchant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  contact_channel: string;
  role_id?: number;
}

export interface CreateMerchantPayload {
  name: string;
  registered_company_id: string;
  google_account_id: string;
  google_location_id: string;
  google_place_id: string;
  google_review_link: string;
  facebook_business_id: string;
  stripe_account_id: string;
  country: string;
  logo?: File;
  base_merchant_id?: number;
}

export interface KeyloopAccount {
  enterpriseId: string;
  storeId: string;
}

export interface UpdateMerchantPayload {
  name?: string;
  registered_company_id?: string;
  google_account_id?: string;
  google_location_id?: string;
  google_place_id?: string;
  google_review_link?: string;
  facebook_business_id?: string;
  stripe_account_id?: string;
  merchantId: number;
  business_description?: string;
  country?: string;
  logo?: File;
  tier?: TierType;
  limits?: MerchantTierLimitsDomain;
  keyloop?: KeyloopAccount;
}

export interface UpdateMerchantBankDetailsPayload {
  merchantId: number;
  bank_account: BankAccountType;
}

export interface CreateMerchantContactPayload {
  merchant_id: number;
  channel: string;
  country_code?: string;
  identifier?: string;
  provider?: string;
}

export interface UpdateMerchantTierPayload {
  tier: string;
  customLimits?: MerchantTierLimitsDomain;
  merchantId: number;
  strictness: string;
}

export interface AddMerchantAssistantPayload {
  merchantId: number;
  externalId: string;
}

class AdminService {
  public static async getAllMerchants(
    { getAccessTokenSilently }: Auth0ContextInterface,
    searchText: string = "",
    offset: number = 0,
    merchantIds: number[] = []
  ): Promise<Merchant[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams: Record<string, string> = {};

    queryParams.offset = `${offset}`;
    queryParams.maxPageSize = `${numberOfAllMerchantsPerLoad}`;
    queryParams.sorting_direction = "desc";

    if (searchText) {
      queryParams.name = searchText;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/admin/merchants?${queryString}&${merchantIds
        .map((mid) => `ids=${mid}`)
        .join("&")}`
    );

    const merchants: AdminMerchantDto[] = data.data;
    return merchants.map(adminMerchantTransformerFromDtoToDomain);
  }

  public static async fetchKeyloopAccounts({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<AdminKeyloopAccountDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      "/admin/keyloop/accounts"
    );

    const adminKeyloopAccounts: AdminKeyloopAccountDTO[] = data.data;

    return adminKeyloopAccounts.map(
      adminKeyloopAccountTransformFromDtoToDomain
    );
  }

  public static async addKeyloopAccount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: {
      accountName: string;
      enterpriseId: string;
      storeId: string;
      merchantIds: number[];
    }
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await postRequest(accessToken, "/admin/keyloop/accounts", {
      account_name: payload.accountName,
      enterprise_id: payload.enterpriseId,
      store_id: payload.storeId,
      merchant_ids: payload.merchantIds,
    });
  }

  public static async updateKeyloopAccount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: {
      accountName: string;
      enterpriseId: string;
      storeId: string;
      merchantIds: number[];
    }
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/admin/keyloop/accounts/${payload.enterpriseId}/${payload.storeId}`,
      {
        account_name: payload.accountName,
        merchant_ids: payload.merchantIds,
      }
    );
  }

  public static async getAllAgents(
    { getAccessTokenSilently }: Auth0ContextInterface,
    searchText: string = "",
    offset: number = 0
  ): Promise<NewAgentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams: Record<string, string> = {};

    queryParams.offset = `${offset}`;
    queryParams.maxPageSize = `${numberOfAllAgentsPerLoad}`;
    queryParams.sorting_direction = "desc";

    if (searchText) {
      queryParams.name = searchText;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/admin/agents?${queryString}`
    );

    const agents: NewAgentDTO[] = data.data;

    return agents.map((a) => newAgentTransformFromDtoToDomain(a));
  }

  public static async createMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateMerchantPayload
  ): Promise<Merchant> {
    const accessToken = await getAccessTokenSilently();
    const formData = new FormData();

    if (payload.name) formData.append("name", payload.name);
    if (payload.country) formData.append("country", payload.country);
    if (payload.registered_company_id)
      formData.append("registered_company_id", payload.registered_company_id);
    if (payload.google_account_id)
      formData.append("google_account_id", payload.google_account_id);
    if (payload.google_location_id)
      formData.append("google_location_id", payload.google_location_id);
    if (payload.google_place_id)
      formData.append("google_place_id", payload.google_place_id);
    if (payload.google_review_link)
      formData.append("google_review_link", payload.google_review_link);
    if (payload.facebook_business_id)
      formData.append("facebook_business_id", payload.facebook_business_id);
    if (payload.stripe_account_id)
      formData.append("stripe_account_id", payload.stripe_account_id);
    if (payload.logo) formData.append("logo", payload.logo);
    if (payload.base_merchant_id)
      formData.append("base_merchant_id", `${payload.base_merchant_id}`);

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/admin/merchants`,
      formData,
      "multipart/form-data"
    );

    const merchant: AdminMerchantDto = data.data;

    return adminMerchantTransformerFromDtoToDomain(merchant);
  }

  public static async updateMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantPayload
  ): Promise<Merchant> {
    const accessToken = await getAccessTokenSilently();

    const formData = new FormData();
    if (payload.name) formData.append("name", payload.name);
    if (payload.country) formData.append("country", payload.country);
    if (payload.registered_company_id)
      formData.append("registered_company_id", payload.registered_company_id);
    if (payload.business_description)
      formData.append("description", payload.business_description);
    if (payload.google_account_id)
      formData.append("google_account_id", payload.google_account_id);
    if (payload.google_location_id)
      formData.append("google_location_id", payload.google_location_id);
    if (payload.google_place_id)
      formData.append("google_place_id", payload.google_place_id);
    if (payload.google_review_link)
      formData.append("google_review_link", payload.google_review_link);
    if (payload.facebook_business_id)
      formData.append("facebook_business_id", payload.facebook_business_id);
    if (payload.stripe_account_id)
      formData.append("stripe_account_id", payload.stripe_account_id);
    if (payload.logo) formData.append("logo", payload.logo);
    if (payload.tier) formData.append("tier", payload.tier);
    if (payload.limits) {
      formData.append(
        "limits",
        JSON.stringify({
          monthly_outgoing_sms_messages:
            payload.limits.monthlyOutgoingSmsMessages,
          monthly_campaign_messages: payload.limits.monthlyCampaignMessages,
        })
      );
    }
    if (payload.keyloop) {
      formData.append(
        "keyloop",
        JSON.stringify({
          enterprise_id: payload.keyloop.enterpriseId,
          store_id: payload.keyloop.storeId,
        })
      );
    }

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/admin/merchants/${payload.merchantId}`,
      formData,
      "multipart/form-data"
    );

    const merchant: AdminMerchantDto = data.data;

    return adminMerchantTransformerFromDtoToDomain(merchant);
  }

  public static async updateMerchantBankDetails(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantBankDetailsPayload
  ): Promise<BankAccountDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await putRequest(
      RequestType.PUT,
      accessToken,
      `/admin/merchants/${payload.merchantId}/bank-account`,
      {
        bank_account: payload.bank_account,
      }
    );

    const merchant: {
      id: string;
      merchant_id: number;
      country: string;
      beneficiary_name: string;
      details: BankDetailsType;
    } = data.data;

    return bankAccountTransformFromDtoToDomain({
      country: merchant.country,
      beneficiary_name: merchant.beneficiary_name,
      details: merchant.details,
    });
  }

  public static async updateMerchantTier(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantTierPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/admin/merchants/${payload.merchantId}/tiers`,
      {
        tier: payload.tier,
        strictness: payload.strictness,
        ...(payload.customLimits !== undefined && {
          custom_limits: {
            monthly_outgoing_sms_messages:
              payload.customLimits?.monthlyOutgoingSmsMessages,
            monthly_campaign_messages:
              payload.customLimits?.monthlyCampaignMessages,
          },
        }),
      }
    );
  }

  public static async addMerchantAssistant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: AddMerchantAssistantPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await postRequest(
      accessToken,
      `/admin/merchants/${payload.merchantId}/assistants`,
      {
        external_id: payload.externalId,
      }
    );
  }

  public static async createContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createChannel: CreateMerchantContactPayload
  ): Promise<ChannelDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      merchant_id,
      channel,
      country_code,
      identifier,
      provider,
    } = createChannel;

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `admin/contacts`,
      {
        merchant_id,
        channel,
        country_code,
        identifier,
        provider,
      }
    );

    const contactChannel: ChannelsDto = data.data;
    return adminContactChannelTransformerFromDtoToDomain(contactChannel);
  }

  public static async updateContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    updateChannel: {
      identifier: string;
      contactId: number;
    }
  ): Promise<ChannelsDto> {
    const accessToken = await getAccessTokenSilently();
    const { identifier, contactId } = updateChannel;

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `admin/contacts/${contactId}`,
      {
        identifier,
      }
    );

    return data.data;
  }

  public static async createAgent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createAgent: CreateAgentPayload
  ): Promise<NewAgentDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      merchant_id,
      first_name,
      last_name,
      email,
      phone,
      contact_channel,
      role_id,
    } = createAgent;

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `admin/agents`,
      {
        first_name,
        last_name,
        email,
        phone,
        contact_channel,
        merchant_id,
        role_id,
      }
    );

    const agent: NewAgentDTO = data.data;

    return newAgentTransformFromDtoToDomain(agent);
  }

  public static async assignAgentToMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: AssignAgentToMerchantPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      agentIds: agent_ids,
      merchantsRoles: merchants_roles,
    } = payload;

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `admin/agents/assign`,
      {
        agent_ids,
        merchants_roles,
      }
    );
  }

  public static async disconnectAgentFromMerchants(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DisconnectAgentFromMerchantsPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      agentId,
      merchantIds: merchant_ids,
    } = payload;

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `admin/agents/${agentId}/assign`,
      {
        merchant_ids,
      }
    );
  }
}

export default AdminService;
