import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import FileDomain from "entities/domain/file";

export interface FilesState {
  files: FileDomain[];
}
const initialState: FilesState = {
  files: [],
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    addFile(state, action: PayloadAction<FileDomain>) {
      const existingFile = state.files.find(
        (file) => file.id === action.payload.id
      );

      if (existingFile) {
        return;
      }

      state.files.push(action.payload);
    },
    setFiles(state, action: PayloadAction<FileDomain[]>) {
      state.files = action.payload;
    },
    removeFile(state, action: PayloadAction<string>) {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },
    removeFiles(state, action: PayloadAction<string[]>) {
      state.files = state.files.filter(
        (file) => !action.payload.includes(file.id)
      );
    },
  },
});

export const { addFile, setFiles, removeFile, removeFiles } =
  filesSlice.actions;
export default filesSlice.reducer;
