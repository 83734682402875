import {
  Badge,
  Box,
  Textarea,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import { SUPPORTED_MEDIA_TYPES } from "components/shared/FilePreview";
import TextLimits, { getCustomFieldsCount } from "./TextLimits";
import Toolbar from "./Toolbar";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";

interface HeaderBuildingBlockProps {
  text: string;
  file: File | null;
  mediaUrl: string | null;
  mediaType: string | null;
  customFields: { [key: string]: string };
  isDisabled: boolean;
  onTextChange: React.Dispatch<React.SetStateAction<string>>;
  clearMedia: () => void;
  onSetFile: React.Dispatch<React.SetStateAction<File | null>>;
  addNewCustomField: (key: string, value: string) => void;
}

enum HeaderType {
  TEXT = "TEXT",
  MEDIA = "MEDIA",
}

interface SelectFormatOption {
  value: HeaderType;
  label: string;
}

const formatOptions: SelectFormatOption[] = [
  {
    value: HeaderType.TEXT,
    label: "Text",
  },
  {
    value: HeaderType.MEDIA,
    label: "Media",
  },
];

const MAX_HEADER_LENGTH = 60;
const MAX_CUSTOM_FIELDS = 1;

const HeaderBuildingBlock = ({
  isDisabled,
  file,
  customFields,
  text,
  mediaUrl,
  mediaType,
  clearMedia,
  onTextChange,
  onSetFile,
  addNewCustomField,
}: HeaderBuildingBlockProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const hasMedia = !!(mediaUrl && mediaType) || !!file;

    setIsOpen(!!text || hasMedia);

    if (hasMedia) {
      setSelectedFormatValue(formatOptions[1]);
    } else {
      setSelectedFormatValue(formatOptions[0]);
    }
  }, [mediaUrl, mediaType, text]);

  const [selectedFormatValue, setSelectedFormatValue] = useState<
    SingleValue<SelectFormatOption>
  >(formatOptions[0]);

  const handleFormatChange = (
    selectedOption: SingleValue<SelectFormatOption>
  ) => {
    if (!selectedOption) {
      return;
    }

    setSelectedFormatValue(selectedOption);
    onTextChange("");
    onSetFile(null);
    clearMedia();
  };

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;

      onTextChange((oldText) => {
        const newText =
          oldText.substring(0, cursorPosition) +
          someText +
          oldText.substring(cursorPosition);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper
        name="Header"
        isOpen={isOpen}
        setIsOpen={
          isDisabled
            ? undefined
            : () => {
                if (isOpen) {
                  setSelectedFormatValue(formatOptions[0]);
                  onTextChange("");
                  onSetFile(null);
                  clearMedia();
                }

                setIsOpen(!isOpen);
              }
        }
      >
        <Select
          id="header-format-select"
          placeholder="Select format"
          onChange={handleFormatChange}
          isDisabled={isDisabled}
          isClearable={false}
          options={formatOptions}
          value={selectedFormatValue}
          menuPortalTarget={document.body}
          styles={{
            ...{
              ...getReactSelectStyles(colorMode, colorScheme),
              container: (provided: any) => ({
                ...getReactSelectStyles(colorMode, colorScheme).container(
                  provided
                ),
                width: isBaseSize ? "100px" : "200px",
              }),
              control: (provided: any) => ({
                ...getReactSelectStyles(colorMode, colorScheme).control(
                  provided
                ),
                minHeight: "2rem",
                maxHeight: "2rem",
              }),
            },
          }}
        />
        {selectedFormatValue?.value === HeaderType.MEDIA ? null : (
          <Toolbar
            customFields={customFields}
            isDisabled={isDisabled}
            onCustomFieldSelect={
              getCustomFieldsCount(text) >= MAX_CUSTOM_FIELDS
                ? undefined
                : insertIntoText
            }
            addNewCustomField={addNewCustomField}
          />
        )}
      </BuildingBlockHeaderWrapper>
      {isOpen ? (
        <>
          <Box
            width="100%"
            height={
              isBaseSize
                ? "8rem"
                : selectedFormatValue?.value === HeaderType.MEDIA
                ? "24rem"
                : "4rem"
            }
            p="1rem"
            px="1.5rem"
            overflow="hidden"
          >
            {selectedFormatValue?.value === HeaderType.MEDIA ? (
              <>
                {!file && !isDisabled && !mediaUrl && !mediaType ? (
                  <FileDragAndDrop
                    isCompact={isBaseSize}
                    setFile={(newFile) => {
                      onSetFile(newFile);
                    }}
                    accept={SUPPORTED_MEDIA_TYPES.join(", ")}
                    onClose={() => {
                      onSetFile(null);
                    }}
                    setIsLoading={() => {}}
                  />
                ) : null}
                <TemplateAttachment
                  file={file}
                  mediaType={mediaType || null}
                  mediaUrl={mediaUrl || null}
                  onAttachmentRemove={() => {
                    onSetFile(null);
                    clearMedia();
                  }}
                />
              </>
            ) : (
              <Textarea
                ref={textAreaRef}
                border="none"
                p={0}
                id="whatsapp-template-header-textarea"
                isDisabled={isDisabled}
                placeholder="e.g. Hello, welcome to our store!"
                value={text}
                onChange={(e) => {
                  onTextChange(e.target.value);
                }}
                maxLength={MAX_HEADER_LENGTH}
                noOfLines={1}
              />
            )}
          </Box>
          {selectedFormatValue?.value === HeaderType.MEDIA ? (
            <Badge
              colorScheme="gray"
              variant="solid"
              position="absolute"
              right={0}
              bottom={0}
            >
              image/video/pdf
            </Badge>
          ) : (
            <TextLimits
              maxCharacters={MAX_HEADER_LENGTH}
              maxCustomFields={MAX_CUSTOM_FIELDS}
              text={text}
            />
          )}
        </>
      ) : null}
    </BuildingBlockWrapper>
  );
};

export default HeaderBuildingBlock;
