import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  VStack,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import FileDomain from "entities/domain/file";
import useDebounce from "hooks/use-debounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { setFiles } from "redux/features/files";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FilesService from "services/files";
import { canDeleteFiles } from "util/permissions";
import { FiUpload } from "react-icons/fi";
import Gallery from "components/shared/Gallery";
import { numberOfFilesPerLoad } from "util/constants";
import { transformFileIntoMedia } from "entities/transformers/file-transformer";
import { useSettingsContext } from "hooks/use-settings-context";
import SmartFileUploader from "components/shared/SmartFileUploader";
import SkeletonOverlay from "./SkeletonOverlay";
import FileNameColumn from "./columns/FileNameColumn";
import FileAvatarColumn from "./columns/FileAvatarColumn";
import FileCreatedAtColumn from "./columns/FileCreatedAtColumn";
import FileUrlColumn from "./columns/FileUrlColumn";
import FileTypeColumn from "./columns/FileTypeColumn";

const hasMoreFiles = (
  filesAmount: number,
  currentPageAmount: number
): boolean => {
  if (filesAmount < numberOfFilesPerLoad) {
    return false;
  }

  if (!currentPageAmount) {
    return false;
  }

  return true;
};

const Files = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { setActionButton } = useSettingsContext();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const { files } = useAppSelector((state) => state.files);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 500);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const gallery = useDisclosure();
  const [startIndex, setStartIndex] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setSearchText("");
      /**
       * Not the best way to do it. Ideally, we would want to keep loaded files.
       * However, somehow when you get back to this page after visiting another one,
       * the files are loaded again with previous search text and then another request
       * shoots but this time from SmartList (to make sure to fetch the last page)
       */
      dispatch(setFiles([]));
    };
  }, []);

  const submitFiles = useCallback(async (filesToSubmit: File[]) => {
    setIsUploading(true);

    try {
      // we don't support multiple files upload yet
      await FilesService.uploadFile(
        auth0Context,
        {
          file: filesToSubmit[0],
          isReusable: true,
          needsShortUrl: false,
        },
        merchant.id
      );
      fetchMoreFiles(true);
    } catch (error: unknown) {
      // eslint-disable-next-line
      console.error("Error uploading files", error);
      toast({
        status: "error",
        title: "Error occurred during file upload!",
      });
    } finally {
      setIsUploading(false);
    }
  }, []);

  const openGallery = useCallback(
    (startId: string) => {
      setStartIndex(files.findIndex((file) => file.id === startId));
      gallery.onOpen();
    },
    [files, gallery]
  );

  const handleDeleteFile = useCallback(async (fileId: string) => {
    try {
      await FilesService.deleteFile(auth0Context, fileId, merchant.id);
      fetchMoreFiles(true);
    } catch (error: unknown) {
      // eslint-disable-next-line
      console.error("Error deleting file", error);
      toast({
        status: "error",
        title: "Error occurred during file deletion!",
      });
    }
  }, []);

  const getIndividualActions = (
    fileItem: FileDomain
  ): SmartListIndividualAction<FileDomain>[] | undefined => {
    if (!fileItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<FileDomain>[] = [];

    if (canDeleteFiles(merchant.id, currentAgent!)) {
      individualActions.push({
        label: "Delete",
        execute: () => {
          handleDeleteFile(fileItem.id);
        },
        shouldShowConfirmation: true,
        confirmationText: "Are you sure you want to delete this file?",
      });
    }

    return individualActions;
  };

  const fetchMoreFiles = async (isReset: boolean = false) => {
    let nextPage: FileDomain[] = [];

    try {
      nextPage = await FilesService.getFiles(
        auth0Context,
        merchant.id,
        debouncedSearchText,
        isReset ? 0 : files.length
      );
    } catch (error: unknown) {
      // eslint-disable-next-line
      console.error("Error fetching files", error);
    }

    const newFiles: FileDomain[] = !isReset ? files.concat(nextPage) : nextPage;

    dispatch(setFiles(newFiles));
    setHasNextPage(hasMoreFiles(newFiles.length, nextPage?.length || 0));
    setIsInitialLoading(false);
  };

  useEffect(() => {
    fetchMoreFiles(true);
  }, [debouncedSearchText]);

  const fileUploadButton = useMemo(() => {
    return (
      <SmartFileUploader
        size={isBaseSize ? "sm" : "md"}
        allowDragAndDrop={false}
        allowMultiple={false}
        useModalForSubmitting={true}
        onSubmit={submitFiles}
        isDisabled={isUploading}
        browseButton={
          isBaseSize ? (
            <IconButton
              aria-label="Upload File"
              icon={<Icon as={FiUpload} />}
              isDisabled={isUploading}
              colorScheme={colorScheme}
            />
          ) : (
            <Button
              colorScheme={colorScheme}
              size="md"
              leftIcon={<Icon as={FiUpload} />}
              isDisabled={isUploading}
            >
              Upload File
            </Button>
          )
        }
      />
    );
  }, [isBaseSize, submitFiles, isUploading, colorScheme]);

  useEffect(() => {
    if (!isBaseSize || !fileUploadButton) {
      setActionButton(null);
      return;
    }

    setActionButton(fileUploadButton);

    return () => setActionButton(null);
  }, [isBaseSize, fileUploadButton]);

  const columns = useMemo(() => {
    return [
      {
        label: "Thumbnail",
        component: FileAvatarColumn,
        additionalProps: {
          openGallery,
        },
      },
      ...(isBaseSize
        ? []
        : [
            {
              label: "Name",
              component: FileNameColumn,
            },
            {
              label: "Type",
              component: FileTypeColumn,
            },
          ]),
      {
        label: "URL",
        component: FileUrlColumn,
      },
      {
        label: "Created At",
        component: FileCreatedAtColumn,
      },
    ];
  }, [isBaseSize, openGallery]);

  return (
    <VStack w="100%" spacing={0} h="100%" overflow="hidden">
      {!isBaseSize && (
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="start"
          py={4}
          gridGap={2}
        >
          {fileUploadButton}
          <ReviewsSearch
            placeholder="tortoise.png"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Flex>
      )}
      <Box
        w="100%"
        h="100%"
        {...(isBaseSize
          ? { overflow: "hidden" }
          : { overflow: "hidden", borderTopRadius: "3xl" })}
      >
        <Box
          overflowY="auto"
          w="100%"
          h="100%"
          {...(isBaseSize
            ? {}
            : {
                borderTopRadius: "3xl",
                bgColor: colorMode === "dark" ? "gray.800" : "white",
              })}
          justifyContent="center"
          alignItems="start"
          ref={scrollContainerRef}
          position="relative"
        >
          {isInitialLoading && <SkeletonOverlay />}
          <SmartList
            items={files}
            itemIdentifier="id"
            canFetchMore={true}
            hasHeader={false}
            hasNextPage={hasNextPage}
            fetchMore={fetchMoreFiles}
            containerRef={scrollContainerRef}
            initialScroll={false}
            columns={columns}
            getIndividualActions={getIndividualActions}
          />
        </Box>
      </Box>
      <Gallery
        isOpen={gallery.isOpen}
        onClose={gallery.onClose}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        media={files.map(transformFileIntoMedia)}
      />
    </VStack>
  );
};

export default Files;
