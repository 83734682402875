import { Flex } from "@chakra-ui/react";
import {
  canManageIntegrations,
  canManageRolePermissions,
} from "util/permissions";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { useSettingsContext } from "hooks/use-settings-context";

interface MobileUserSettingsProps {}

enum MobileSettingsRoutes {
  ACCOUNT_OVERVIEW = "account",
  CHANNELS = "channels",
  INTEGRATION_HUB = "integrations",
  TEMPLATE_MESSAGES = "templates",
  TEAM_MEMBERS = "teammates",
  PERMISSIONS = "permissions",
  FILES = "files",
}

const MobileUserSettings = (_props: MobileUserSettingsProps) => {
  const { setShouldDisplayBackButton } = useSettingsContext();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const location = useLocation();
  const navigate = useNavigate();
  const [isInitialPage, setIsInitialPage] = useState<boolean>(
    location.pathname.split("/").filter((p) => !!p).length <= 2
  );

  useEffect(() => {
    const path = location.pathname.split("/").filter((p) => !!p);

    if (path.length > 2) {
      setIsInitialPage(false);
    } else {
      setIsInitialPage(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setShouldDisplayBackButton(!isInitialPage);
  }, [isInitialPage]);

  if (isInitialPage) {
    return (
      <>
        <Flex
          py="2rem"
          pl="2rem"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="gray.400"
          onClick={() => {
            navigate(
              `/${merchant.id}/settings/${MobileSettingsRoutes.ACCOUNT_OVERVIEW}`
            );
          }}
        >
          Account Overview
        </Flex>
        {!canManageIntegrations(merchant.id, currentAgent!) ? null : (
          <Flex
            py="2rem"
            pl="2rem"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.400"
            onClick={() =>
              navigate(
                `/${merchant.id}/settings/${MobileSettingsRoutes.CHANNELS}`
              )
            }
          >
            Channels
          </Flex>
        )}
        {!canManageIntegrations(merchant.id, currentAgent!) ? null : (
          <Flex
            py="2rem"
            pl="2rem"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.400"
            onClick={() =>
              navigate(
                `/${merchant.id}/settings/${MobileSettingsRoutes.INTEGRATION_HUB}`
              )
            }
          >
            Integration Hub
          </Flex>
        )}
        <Flex
          py="2rem"
          pl="2rem"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="gray.400"
          onClick={() =>
            navigate(
              `/${merchant.id}/settings/${MobileSettingsRoutes.TEMPLATE_MESSAGES}`
            )
          }
        >
          Templates
        </Flex>
        <Flex
          py="2rem"
          pl="2rem"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="gray.400"
          onClick={() =>
            navigate(
              `/${merchant.id}/settings/${MobileSettingsRoutes.TEAM_MEMBERS}`
            )
          }
        >
          Team Members
        </Flex>
        {canManageRolePermissions(merchant.id, currentAgent!) ? (
          <Flex
            py="2rem"
            pl="2rem"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.400"
            onClick={() =>
              navigate(
                `/${merchant.id}/settings/${MobileSettingsRoutes.PERMISSIONS}`
              )
            }
          >
            Role Permissions
          </Flex>
        ) : null}
        <Flex
          py="2rem"
          pl="2rem"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="gray.400"
          onClick={() =>
            navigate(`/${merchant.id}/settings/${MobileSettingsRoutes.FILES}`)
          }
        >
          Files
        </Flex>
      </>
    );
  }

  return <Outlet />;
};

export default MobileUserSettings;
