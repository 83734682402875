import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Progress,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import useMerchantStore from "hooks/use-merchant-store";
import ReactDatePicker from "react-datepicker";
import React, { useEffect, useRef, useState } from "react";
import {
  getMerchantTimeZoneByCountryCode,
  isFuzeyMerchant,
} from "util/methods";
import { useAuth0 } from "@auth0/auth0-react";
import { canManageAccount } from "util/permissions";
import { ReactComponent as TwentyFourSevenIcon } from "assets/icons/247service.svg";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FilesService from "services/files";
import { WorkingHoursDayDomain } from "entities/domain/working-hours";
import MerchantService from "services/merchant";
import { setWorkingHours } from "redux/features/merchant";
import { FaLock, FaLockOpen } from "react-icons/fa";
import AccountOverviewItem from "./AccountOverviewItem";
import UploadComponent from "../TeamMemberSettings/UploadComponent";

interface AccountOverviewProps {}

const getDayNameByIndex = (index: number): string => {
  switch (index) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
};

const reader = new FileReader();

const isTwentyFourSeven = (day: WorkingHoursDayDomain | null): boolean => {
  if (!day) return false;

  return (
    day.open.hour === 0 &&
    day.open.minute === 0 &&
    day.close.hour === 23 &&
    day.close.minute === 59
  );
};

const AccountOverview = (_props: AccountOverviewProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const auth0Context = useAuth0();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { currentAgent, loading: agentLoading } = useAppSelector(
    (state) => state.agents
  );
  const { fetchMerchantUsage, updateMerchant } = useMerchantStore();
  const {
    usage,
    merchant,
    workingHours,
    timeZoneOffset: offsetInHours,
  } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newLogoUrl, setNewLogoUrl] = useState<string | null>(null);
  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [isWorkingHoursModalOpened, setIsWorkingHoursModalOpened] =
    useState<boolean>(false);
  const [newWorkingHours, setNewWorkingHours] = useState<
    Array<WorkingHoursDayDomain | null>
  >(
    workingHours || [
      ...Array(5).fill(
        new WorkingHoursDayDomain(
          {
            hour: 9,
            minute: 0,
          },
          {
            hour: 17,
            minute: 0,
          }
        )
      ),
      null,
      null,
    ]
  );

  useEffect(() => {
    setNewWorkingHours(
      workingHours || [
        ...Array(5).fill(
          new WorkingHoursDayDomain(
            {
              hour: 9,
              minute: 0,
            },
            {
              hour: 17,
              minute: 0,
            }
          )
        ),
        null,
        null,
      ]
    );
  }, [workingHours]);

  useEffect(() => {
    MerchantService.getWorkingHours(auth0Context, merchant.id)
      .then((fetchedWorkingHours) => {
        dispatch(setWorkingHours(fetchedWorkingHours));
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Failed to fetch working hours for this merchant",
        });
      });
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const uploadAndSaveLogo = async () => {
    setIsLoading(true);

    let fileUrl = null;

    try {
      fileUrl = (
        await FilesService.uploadFile(
          auth0Context,
          {
            file: newLogo as File,
            isReusable: false,
            needsShortUrl: false,
          },
          merchant.id
        )
      ).url;
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Failed to upload file of new account logo", e);
    } finally {
      setIsLoading(false);
    }

    if (!fileUrl) {
      toast({
        status: "error",
        title: "Failed to upload this file. Please try again.",
      });

      return;
    }

    try {
      await updateMerchant({
        logoUrl: fileUrl,
      });

      toast({
        status: "success",
        title: "Successfully saved merchant's new logo",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Failed to save merchant's new logo", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNewLogoUrl(merchant.externalMetadata.logo);
    setNewLogo(null);
  }, [merchant.externalMetadata.logo]);

  useEffect(() => {
    if (newLogo) {
      reader.readAsDataURL(newLogo);
      reader.onload = () => {
        setNewLogoUrl(reader.result as string);
      };
    }
  }, [newLogo]);

  useEffect(() => {
    if (isFuzeyMerchant(merchant.name) && !usage) {
      fetchMerchantUsage();
    }
  }, []);

  if ((isFuzeyMerchant(merchant.name) && usage === undefined) || agentLoading) {
    return <Spinner />;
  }

  return (
    <Flex
      w="100%"
      h="100%"
      {...(isBaseSize
        ? {}
        : {
            mt: "4.5rem",
            borderTopRadius: "3xl",
            bgColor: colorMode === "dark" ? "gray.800" : "white",
          })}
      justifyContent="center"
      alignItems="start"
      overflow="hidden"
    >
      <Box h="100%" w="100%" pb={isBaseSize ? 8 : 20} overflowY="auto">
        <Box w={isBaseSize ? "90%" : "60%"} mx="auto">
          <AccountOverviewItem.Default
            icon="/building.svg"
            text={`Business Name: ${merchant.name}`}
          />
          <AccountOverviewItem.Default
            icon="/Phone-ds.svg"
            text={`Phone: ${merchant.getPhoneNumber()}`}
          />
          <AccountOverviewItem.Default
            icon="/pin.svg"
            text={`Location: ${merchant.location?.displayName}`}
          />
          {isFuzeyMerchant(merchant.name) && usage && (
            <AccountOverviewItem.Openable
              icon="/car-speed-limiter.svg"
              actionIcon="/edit.svg"
              text="Usage Limits"
            >
              {usage.getLabelsWithValues().map((row, index, arr) => (
                <>
                  {index !== 0 && <Divider my={8} />}
                  <Flex
                    mt={index === 0 ? 10 : 0}
                    mb={index === arr.length - 1 ? 6 : 0}
                    key={row.label}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight={700} mb={4}>
                      {row.label}
                    </Text>
                    <Progress
                      w="100%"
                      value={row.progress}
                      colorScheme={colorScheme}
                    />
                    <Text>{row.value}</Text>
                  </Flex>
                </>
              ))}
            </AccountOverviewItem.Openable>
          )}
          {isFuzeyMerchant(merchant.name) && (
            <AccountOverviewItem.Openable
              icon="/camera.svg"
              actionIcon="/eye.svg"
              text="Location Avatar"
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                gridGap={4}
                my={8}
              >
                <Text fontWeight={700} mb={4}>
                  Upload Avatar for this Business Location
                </Text>
                {merchant.externalMetadata.logo &&
                !canManageAccount(merchant.id, currentAgent!) ? (
                  <Avatar
                    key={merchant.id}
                    src={merchant.externalMetadata.logo}
                    name={merchant.name}
                    size="md"
                  />
                ) : null}
                {canManageAccount(merchant.id, currentAgent!) && (
                  <>
                    <UploadComponent
                      profilePicSrc={newLogoUrl || ""}
                      onButtonClick={() => {
                        inputRef?.current?.click();
                      }}
                      setProfilePicture={setNewLogo}
                      inputRef={inputRef}
                      name={merchant.name}
                    />
                    <Button
                      isDisabled={!newLogo || isLoading}
                      colorScheme={colorScheme}
                      onClick={uploadAndSaveLogo}
                    >
                      Upload & Save
                    </Button>
                  </>
                )}
              </Flex>
            </AccountOverviewItem.Openable>
          )}
          {newWorkingHours.length ? (
            <AccountOverviewItem.Openable
              icon="/clock-two.svg"
              actionIcon="/eye.svg"
              text="Working Hours"
              isOpenModal={isWorkingHoursModalOpened}
              setIsOpenModal={setIsWorkingHoursModalOpened}
            >
              <VStack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                w="100%"
              >
                <Text fontWeight="bold" fontSize="xl" mb={4}>
                  {`${
                    canManageAccount(merchant.id, currentAgent!)
                      ? "Update "
                      : ""
                  } Business Working Hours`}
                </Text>
                {new Date().getTimezoneOffset() / 60 !== offsetInHours ? (
                  <Alert status="info" borderRadius="xl">
                    <AlertIcon />
                    Time must be specified in the{" "}
                    {getMerchantTimeZoneByCountryCode(merchant.country)}{" "}
                    timezone
                  </Alert>
                ) : null}
                {newWorkingHours.map((day, index) => (
                  <FormControl key={index}>
                    <FormLabel fontWeight="bold">
                      {getDayNameByIndex(index)}
                    </FormLabel>
                    <HStack spacing={2} justifyContent="space-between">
                      {day ? (
                        <>
                          <Text
                            color={
                              colorMode === "dark" ? "gray.200" : "gray.600"
                            }
                          >
                            {`From${
                              canManageAccount(merchant.id, currentAgent!)
                                ? ""
                                : day.getOpenTime()
                            }`}
                          </Text>
                          {canManageAccount(merchant.id, currentAgent!) ? (
                            <Box
                              rounded="full"
                              border="none"
                              textAlign="left"
                              bgColor={
                                colorMode === "dark" ? "black" : "gray.50"
                              }
                              px={3}
                              css={{
                                input: {
                                  height: isBaseSize ? "2rem" : "2.5rem",
                                  width: "100%",
                                  backgroundColor: "inherit",
                                },
                                ".react-datepicker-wrapper": {
                                  width: "100%",
                                },
                              }}
                            >
                              <ReactDatePicker
                                selected={day ? day.getDate(day.open) : null}
                                placeholderText={day ? "From" : "Closed"}
                                onChange={(newDate) => {
                                  if (!day) {
                                    return;
                                  }

                                  setNewWorkingHours((prev) => {
                                    const updatedWorkingHours = [...prev];

                                    updatedWorkingHours[index] =
                                      new WorkingHoursDayDomain(
                                        {
                                          hour: newDate!.getHours(),
                                          minute: newDate!.getMinutes(),
                                        },
                                        updatedWorkingHours[index]!.close
                                      );

                                    return updatedWorkingHours;
                                  });
                                }}
                                selectsStart={true}
                                disabled={!day}
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                              />
                            </Box>
                          ) : null}
                          <Text
                            color={
                              colorMode === "dark" ? "gray.200" : "gray.600"
                            }
                          >
                            {`to${
                              canManageAccount(merchant.id, currentAgent!)
                                ? ""
                                : day.getCloseTime()
                            }`}
                          </Text>
                          {canManageAccount(merchant.id, currentAgent!) ? (
                            <Box
                              rounded="full"
                              border="none"
                              textAlign="left"
                              bgColor={
                                colorMode === "dark" ? "black" : "gray.50"
                              }
                              px={3}
                              css={{
                                input: {
                                  height: isBaseSize ? "2rem" : "2.5rem",
                                  width: "100%",
                                  backgroundColor: "inherit",
                                },
                                ".react-datepicker-wrapper": {
                                  width: "100%",
                                },
                              }}
                            >
                              <ReactDatePicker
                                selected={day ? day.getDate(day.close) : null}
                                placeholderText={day ? "To" : "Closed"}
                                disabled={!day}
                                onChange={(newDate) => {
                                  if (!day) {
                                    return;
                                  }

                                  setNewWorkingHours((prev) => {
                                    const updatedWorkingHours = [...prev];

                                    updatedWorkingHours[index] =
                                      new WorkingHoursDayDomain(
                                        updatedWorkingHours[index]!.open,
                                        {
                                          hour: newDate!.getHours(),
                                          minute: newDate!.getMinutes(),
                                        }
                                      );

                                    return updatedWorkingHours;
                                  });
                                }}
                                selectsEnd={true}
                                showTimeSelectOnly={true}
                                showTimeSelect={true}
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                              />
                            </Box>
                          ) : null}
                        </>
                      ) : (
                        <Text
                          color={colorMode === "dark" ? "gray.200" : "gray.600"}
                        >
                          Closed
                        </Text>
                      )}
                      {canManageAccount(merchant.id, currentAgent!) ? (
                        <HStack>
                          <IconButton
                            aria-label={!day ? "Open" : "Closed"}
                            colorScheme="gray"
                            variant="ghost"
                            icon={
                              <Icon
                                boxSize={4}
                                as={day ? FaLockOpen : FaLock}
                              />
                            }
                            onClick={() => {
                              if (!day) {
                                setNewWorkingHours((prev) => {
                                  const updatedWorkingHours = [...prev];

                                  updatedWorkingHours[index] =
                                    (workingHours && workingHours[index]) ||
                                    new WorkingHoursDayDomain(
                                      {
                                        hour: 9 + offsetInHours,
                                        minute: 0,
                                      },
                                      {
                                        hour: 17 + offsetInHours,
                                        minute: 0,
                                      }
                                    );

                                  return updatedWorkingHours;
                                });
                              } else {
                                setNewWorkingHours((prev) => {
                                  const updatedWorkingHours = [...prev];

                                  updatedWorkingHours[index] = null;

                                  return updatedWorkingHours;
                                });
                              }
                            }}
                          />
                          <IconButton
                            aria-label="24/7 service"
                            colorScheme="gray"
                            variant="ghost"
                            isActive={isTwentyFourSeven(day)}
                            icon={
                              <Icon
                                boxSize={4}
                                as={TwentyFourSevenIcon}
                                __css={{
                                  path: {
                                    fill:
                                      colorMode === "dark"
                                        ? "white!important"
                                        : "black!important",
                                  },
                                }}
                              />
                            }
                            onClick={() => {
                              if (isTwentyFourSeven(day)) {
                                setNewWorkingHours((prev) => {
                                  const updatedWorkingHours = [...prev];

                                  updatedWorkingHours[index] =
                                    (workingHours && workingHours[index]) ||
                                    new WorkingHoursDayDomain(
                                      {
                                        hour: 9 + offsetInHours,
                                        minute: 0,
                                      },
                                      {
                                        hour: 17 + offsetInHours,
                                        minute: 0,
                                      }
                                    );

                                  return updatedWorkingHours;
                                });
                              } else {
                                setNewWorkingHours((prev) => {
                                  const updatedWorkingHours = [...prev];

                                  updatedWorkingHours[index] =
                                    new WorkingHoursDayDomain(
                                      {
                                        hour: 0 + offsetInHours,
                                        minute: 0,
                                      },
                                      {
                                        hour: 23 + offsetInHours,
                                        minute: 59,
                                      }
                                    );

                                  return updatedWorkingHours;
                                });
                              }
                            }}
                          />
                        </HStack>
                      ) : null}
                    </HStack>
                  </FormControl>
                ))}
                {canManageAccount(merchant.id, currentAgent!) && (
                  <Button
                    alignSelf="end"
                    isDisabled={isLoading}
                    colorScheme={colorScheme}
                    size="md"
                    my={2}
                    onClick={() => {
                      MerchantService.updateWorkingHours(
                        auth0Context,
                        newWorkingHours,
                        merchant.id
                      )
                        .then(() => {
                          toast({
                            status: "success",
                            title:
                              "Successfully saved merchant's new working hours",
                          });
                          setIsWorkingHoursModalOpened(false);
                        })
                        .catch(() => {
                          toast({
                            status: "error",
                            title:
                              "Failed to save merchant's new working hours",
                          });
                        });
                    }}
                  >
                    Save
                  </Button>
                )}
              </VStack>
            </AccountOverviewItem.Openable>
          ) : null}
        </Box>
      </Box>
    </Flex>
  );
};

export default AccountOverview;
