import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useColorMode,
  Icon,
  VStack,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp.svg";
import FullTemplateDomain from "entities/domain/templates/full_template";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import useTemplatesStore from "hooks/use-templates-store";
import { useWebSocket } from "hooks/use-socket";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TemplateCreatedMessage,
  TemplateUpdatedMessage,
} from "entities/ISocketArgs";
import { templateTransformFromFullDtoToDomain } from "entities/transformers/templateTransformers";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { cleanContactsToastMessages } from "redux/features/contacts";
import {
  TemplateFilter,
  propagateTemplateUpdate,
  setactiveTemplateFilter,
} from "redux/features/templates";
import { FaPlus } from "react-icons/fa";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import useDebounce from "hooks/use-debounce";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { isWhatsappTemplateDTO } from "entities/dto/WhatsappTemplateDTO";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import { useSettingsContext } from "hooks/use-settings-context";
import { whatsappTemplateTransformFromDtoToDomain } from "entities/transformers/whatsapp-template-transformer";
import { AddIcon } from "@chakra-ui/icons";
import { isFullTemplateDTO } from "entities/dto/TemplateDTO";
import SkeletonOverlay from "./SkeletonOverlay";
import TemplateList from "./TemplateList";
import ChooseTemplateTypeModal from "./ChooseTemplateTypeModal";

const getTemplateChannelByIndex = (index: number): TemplateFilter => {
  switch (index) {
    case 0:
      return TemplateFilter.WHATSAPP;
    case 1:
      return TemplateFilter.REGULAR;
    default:
      return TemplateFilter.WHATSAPP;
  }
};

const getactiveTemplateFilterIndex = (
  channel: TemplateFilter | undefined
): number => {
  switch (channel) {
    case TemplateFilter.WHATSAPP:
      return 0;
    case TemplateFilter.REGULAR:
      return 1;
    default:
      return 0;
  }
};

const TemplateSettings = () => {
  const { colorMode } = useColorMode();
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { setActionButton } = useSettingsContext();
  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { fetchTemplates } = useTemplatesStore();
  const toast = useToast();
  const { toastMessage, isLoading, activeTemplateFilter } = useAppSelector(
    (state) => state.templates
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(isLoading);
  const [templateUpdate, setTemplateUpdate] = useState<TemplateUpdatedMessage>(
    {} as TemplateUpdatedMessage
  );
  const [templateCreate, setTemplateCreate] = useState<TemplateCreatedMessage>(
    {} as TemplateCreatedMessage
  );
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 500);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const channel = queryParams.get("channel") as TemplateFilter | null;

    if (!channel) {
      if (merchant.isMerchantChannelEnabled(ConversationChannel.WHATSAPP)) {
        dispatch(setactiveTemplateFilter(TemplateFilter.WHATSAPP));
        setSearchParams({
          ...currentSearchParameters,
          channel: TemplateFilter.WHATSAPP,
        });
      } else {
        dispatch(setactiveTemplateFilter(TemplateFilter.REGULAR));
        setSearchParams({
          ...currentSearchParameters,
          channel: TemplateFilter.REGULAR,
        });
      }

      return;
    }

    dispatch(setactiveTemplateFilter(channel || undefined));
  }, [currentSearchParameters]);

  useEffect(() => {
    const isWhatsappViewModalRoute = location.pathname
      .split("/")
      .filter((p) => !!p)
      .some((p) => p === "whatsapp");

    if (isWhatsappViewModalRoute) {
      setIsInitialLoading(false);
      return;
    }

    setIsInitialLoading(true);
    fetchTemplates(debouncedSearchText).finally(() => {
      setIsInitialLoading(false);
    });
  }, [debouncedSearchText, activeTemplateFilter]);

  const handleTemplateCreated = (args: TemplateCreatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setTemplateCreate(args);
  };

  useEffect(() => {
    addEventHandler("template_created", handleTemplateCreated);
    addEventHandler("template_updated", handleTemplateUpdated);
    return () => {
      removeEventHandler("template_created", handleTemplateCreated);
      removeEventHandler("template_updated", handleTemplateUpdated);
    };
  }, [addEventHandler, removeEventHandler]);
  const handleTemplateUpdated = (args: TemplateUpdatedMessage) => {
    if (args.merchantId !== merchant.id) {
      return;
    }

    setTemplateUpdate(args);
  };

  useEffect(() => {
    if (templateCreate.template) {
      if (
        activeTemplateFilter === TemplateFilter.WHATSAPP &&
        isWhatsappTemplateDTO(templateCreate.template)
      ) {
        const template: WhatsappTemplate =
          whatsappTemplateTransformFromDtoToDomain(templateCreate.template);

        dispatch(propagateTemplateUpdate(template));

        return;
      }

      if (
        activeTemplateFilter !== TemplateFilter.WHATSAPP &&
        isFullTemplateDTO(templateCreate.template)
      ) {
        const template: FullTemplateDomain =
          templateTransformFromFullDtoToDomain(templateCreate.template);

        dispatch(propagateTemplateUpdate(template));

        return;
      }
    }
  }, [templateCreate]);

  useEffect(() => {
    if (templateUpdate.template) {
      if (
        activeTemplateFilter === TemplateFilter.WHATSAPP &&
        isWhatsappTemplateDTO(templateUpdate.template)
      ) {
        const template: WhatsappTemplate =
          whatsappTemplateTransformFromDtoToDomain(templateUpdate.template);

        dispatch(propagateTemplateUpdate(template));

        return;
      }

      if (
        activeTemplateFilter !== TemplateFilter.WHATSAPP &&
        isFullTemplateDTO(templateUpdate.template)
      ) {
        const template: FullTemplateDomain =
          templateTransformFromFullDtoToDomain(templateUpdate.template);

        dispatch(propagateTemplateUpdate(template));

        return;
      }
    }
  }, [templateUpdate]);

  // remove this shit
  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast({ status: "success", title: toastMessage.success });
      } else if (toastMessage.errors) {
        toast({ status: "error", title: toastMessage.errors[0] });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [toastMessage]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const chooseTemplateTypeModal = useDisclosure();
  const openModal = useCallback(() => {
    chooseTemplateTypeModal.onOpen();
  }, [chooseTemplateTypeModal.onOpen]);

  const createTemplateButton = useMemo(() => {
    return (
      <IconButton
        aria-label="Add Template"
        icon={<Icon as={AddIcon} />}
        colorScheme={colorScheme}
        onClick={openModal}
      />
    );
  }, [openModal, colorScheme]);

  useEffect(() => {
    if (!isBaseSize || !createTemplateButton) {
      setActionButton(null);
      return;
    }

    setActionButton(createTemplateButton);

    return () => setActionButton(null);
  }, [isBaseSize, createTemplateButton]);

  return (
    <VStack w="100%" h="100%" spacing={0}>
      {isBaseSize ? null : (
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="start"
          py={4}
          gridGap={2}
        >
          <Button
            colorScheme={colorScheme}
            gridGap={2}
            size="md"
            onClick={() => {
              if (
                merchant.isMerchantChannelEnabled(ConversationChannel.WHATSAPP)
              ) {
                chooseTemplateTypeModal.onOpen();
              } else {
                navigate(`/${merchant.id}/settings/templates/create`);
              }
            }}
          >
            <Icon
              as={FaPlus}
              fill={colorMode === "dark" ? `${colorScheme}.800` : "white"}
            />
            <Text>Create Template</Text>
          </Button>
          {activeTemplateFilter === TemplateFilter.WHATSAPP ? null : (
            <ReviewsSearch
              placeholder="Auto Reply"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          )}
        </Flex>
      )}
      <ChooseTemplateTypeModal
        isOpen={chooseTemplateTypeModal.isOpen}
        onClose={chooseTemplateTypeModal.onClose}
      />
      <Box
        w="100%"
        h="100%"
        {...(isBaseSize ? {} : { overflow: "hidden", borderTopRadius: "3xl" })}
      >
        <Box
          w="100%"
          h="100%"
          borderTopRadius="3xl"
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          justifyContent="center"
          alignItems="center"
        >
          {isBaseSize ? (
            <Box w="100%" h="100%">
              <Box p={2}>
                <ReviewsSearch
                  placeholder="Auto Reply"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </Box>
              <Tabs
                isLazy={true}
                lazyBehavior="unmount"
                onChange={(index) => {
                  setSearchParams({
                    ...currentSearchParameters,
                    channel: getTemplateChannelByIndex(index),
                  });
                }}
                index={getactiveTemplateFilterIndex(activeTemplateFilter) || 0}
                height="100%"
                colorScheme={colorScheme}
                variant="soft-rounded"
                size="md"
                w="100%"
                isFitted={true}
              >
                <TabList justifyContent="center" mx={2}>
                  <Tab
                    fontSize="sm"
                    isDisabled={
                      !merchant.isMerchantChannelEnabled(
                        ConversationChannel.WHATSAPP
                      )
                    }
                  >
                    WhatsApp
                  </Tab>
                  <Tab fontSize="sm">Regular</Tab>
                </TabList>

                <TabPanels
                  h="100%"
                  w="100%"
                  position="relative"
                  overflowY="auto"
                  ref={scrollContainerRef}
                >
                  {isInitialLoading && <SkeletonOverlay />}
                  <TabPanel h="100%" w="100%" p={0}>
                    <TemplateList scrollContainerRef={scrollContainerRef} />
                  </TabPanel>
                  <TabPanel h="100%" w="100%" p={0}>
                    <TemplateList scrollContainerRef={scrollContainerRef} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          ) : (
            <>
              <Flex
                gridGap={2}
                justifyContent="start"
                alignItems="center"
                w="100%"
                p={4}
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor={
                  colorMode === "dark" ? "gray.700" : "gray.200"
                }
              >
                <Button
                  isActive={activeTemplateFilter === TemplateFilter.WHATSAPP}
                  variant="ghost"
                  onClick={() => {
                    if (activeTemplateFilter === TemplateFilter.WHATSAPP) {
                      return;
                    }

                    setSearchParams({
                      ...currentSearchParameters,
                      channel: TemplateFilter.WHATSAPP,
                    });
                  }}
                  colorScheme={colorScheme}
                  rightIcon={<Icon as={WhatsappIcon} />}
                  isDisabled={
                    isInitialLoading ||
                    !merchant.isMerchantChannelEnabled(
                      ConversationChannel.WHATSAPP
                    )
                  }
                >
                  WhatsApp
                </Button>
                <Button
                  isActive={activeTemplateFilter === TemplateFilter.REGULAR}
                  variant="ghost"
                  onClick={() => {
                    if (activeTemplateFilter === TemplateFilter.REGULAR) {
                      return;
                    }

                    setSearchParams({
                      ...currentSearchParameters,
                      channel: TemplateFilter.REGULAR,
                    });
                  }}
                  colorScheme={colorScheme}
                  isDisabled={isInitialLoading}
                >
                  Regular
                </Button>
              </Flex>
              <Box
                h="100%"
                w="100%"
                position="relative"
                overflowY="auto"
                ref={scrollContainerRef}
              >
                {isInitialLoading && <SkeletonOverlay />}
                <TemplateList scrollContainerRef={scrollContainerRef} />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Outlet />
    </VStack>
  );
};

export default TemplateSettings;
