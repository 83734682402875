import { Flex, Icon } from "@chakra-ui/react";
import FullTemplateDomain from "entities/domain/templates/full_template";
import React from "react";
import { ReactSVG } from "react-svg";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { getChannelIcon } from "util/constants";

const TemplateChannelsColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}) => {
  if (item.isRegularTemplate()) {
    return (
      <Flex justifyContent="center" alignItems="center" gridGap={2}>
        {item.channels.map((chan) => (
          <Icon
            mr={2}
            key={chan}
            as={ReactSVG}
            src={getChannelIcon(chan)}
            __css={{ svg: { height: "1rem", width: "1rem" } }}
          />
        ))}
      </Flex>
    );
  }

  return null;
};

export default TemplateChannelsColumn;
