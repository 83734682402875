import { useBreakpointValue, useToast } from "@chakra-ui/react";
import SmartList, {
  SmartListColumn,
  SmartListIndividualAction,
} from "components/shared/SmartList";
import FullTemplateDomain from "entities/domain/templates/full_template";
import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import useTemplatesStore from "hooks/use-templates-store";
import TemplateDomain from "entities/domain/templates";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import {
  RegularOrWhatsappTemplate,
  TemplateFilter,
} from "redux/features/templates";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { whatsappTemplateTransformFromDomainToDto } from "entities/transformers/whatsapp-template-transformer";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import { templateTransformFromFullTemplateDomainToDto } from "entities/transformers/templateTransformers";
import TemplateTitleColumn from "./columns/TemplateTitleColumn";
import TemplateShortcutColumn from "./columns/TemplateShortcutColumn";
import TemplatePreviewColumn from "./columns/TemplatePreviewColumn";
import TemplateWhatsAppStatusColumn from "./columns/TemplateWhatsappStatusColumn";
import TemplateChannelsColumn from "./columns/TemplateChannelsColumn";

interface TemplateListProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const TemplateList = ({ scrollContainerRef }: TemplateListProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const {
    markTemplateAsFavouriteOrGeneral,
    deleteTemplate,
    syncTemplate,
    syncWhatsappTemplate,
  } = useTemplatesStore();

  const { templates, activeTemplateFilter } = useAppSelector(
    (state) => state.templates
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { search } = useLocation();

  const getIndividualActions = (
    templateItem: RegularOrWhatsappTemplate
  ): SmartListIndividualAction<RegularOrWhatsappTemplate>[] | undefined => {
    if (!templateItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<RegularOrWhatsappTemplate>[] =
      [];

    if (templateItem instanceof FullTemplateDomain) {
      individualActions.push({
        label: templateItem.favourite
          ? "Remove from favourites"
          : "Add to favourites",
        execute: () => {
          markTemplateAsFavouriteOrGeneral(
            templateItem as TemplateDomain,
            !templateItem.favourite
          );
        },
      });

      individualActions.push({
        label: "Duplicate",
        execute: () => {
          try {
            const templateItemDto =
              templateTransformFromFullTemplateDomainToDto(
                templateItem,
                merchant.id
              );

            navigate(
              {
                pathname: `/${merchant.id}/settings/templates/create`,
                search,
              },
              {
                state: templateItemDto,
              }
            );
          } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error duplicating template", error);

            toast({
              status: "error",
              title:
                "Cannot duplicate template at the moment! Please try again later.",
            });

            return;
          }
        },
      });
    }

    if (
      templateItem.canEdit() &&
      templateItem instanceof FullTemplateDomain &&
      !templateItem.channels.includes(ConversationChannel.WHATSAPP)
    ) {
      individualActions.push({
        label: "Edit",
        execute: () => {
          navigate({
            pathname: `/${merchant.id}/settings/templates/${templateItem.id}`,
            search,
          });
        },
        shouldShowConfirmation: !!templateItem.channels.includes(
          ConversationChannel.WHATSAPP
        ),
        confirmationText:
          "Making changes to the text content of this template will resend the revised template to WhatsApp for approval. Would you like to continue?",
      });
    }

    if (templateItem instanceof WhatsappTemplate) {
      individualActions.push({
        label: "View",
        execute: () => {
          navigate({
            pathname: `/${merchant.id}/settings/templates/whatsapp/${templateItem.id}`,
            search: createSearchParams(search).toString(),
          });
        },
      });

      individualActions.push({
        label: "Duplicate",
        execute: () => {
          try {
            const templateItemDto =
              whatsappTemplateTransformFromDomainToDto(templateItem);

            navigate(
              {
                pathname: `/${merchant.id}/settings/templates/whatsapp/create`,
                search,
              },
              {
                state: templateItemDto,
              }
            );
          } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error("Error duplicating template", error);

            toast({
              status: "error",
              title:
                "Cannot duplicate template at the moment! Please try again later.",
            });

            return;
          }
        },
      });
    }

    if (
      templateItem.canEdit() &&
      templateItem instanceof FullTemplateDomain &&
      templateItem.channels.includes(ConversationChannel.WHATSAPP)
    ) {
      individualActions.push({
        label: "View",
        execute: () => {
          navigate({
            pathname: `/${merchant.id}/settings/templates/${templateItem.id}`,
            search,
          });
        },
      });
    }

    if (
      templateItem instanceof FullTemplateDomain &&
      templateItem.channels.includes(ConversationChannel.WHATSAPP)
    ) {
      individualActions.push({
        label: "Sync",
        execute: () => {
          syncTemplate({ id: templateItem.id });
        },
        shouldShowConfirmation: false,
      });
    }

    if (templateItem instanceof WhatsappTemplate) {
      individualActions.push({
        label: "Sync",
        execute: () => {
          syncWhatsappTemplate({ id: templateItem.id });
        },
        shouldShowConfirmation: false,
      });
    }

    if (
      !templateItem.isGeneric() &&
      templateItem instanceof FullTemplateDomain
    ) {
      individualActions.push({
        label: "Delete",
        execute: () => {
          deleteTemplate({ id: templateItem.id });
        },
        shouldShowConfirmation: true,
        confirmationText: "Are you sure you want to delete this template?",
      });
    }

    return individualActions;
  };

  const templateColumns = useMemo(() => {
    const columns: SmartListColumn<RegularOrWhatsappTemplate>[] = [
      {
        label: "Title",
        component: TemplateTitleColumn,
      },
    ];

    if (!isBaseSize) {
      if (activeTemplateFilter !== TemplateFilter.WHATSAPP) {
        columns.push({
          label: "Channels",
          component: TemplateChannelsColumn,
        });
        columns.push({
          label: "Shortcut",
          component: TemplateShortcutColumn,
        });
      }

      columns.push({
        label: "Preview",
        component: TemplatePreviewColumn,
      });
    }

    if (activeTemplateFilter === TemplateFilter.WHATSAPP) {
      columns.push({
        label: "Status",
        component: TemplateWhatsAppStatusColumn,
      });
    }

    return columns;
  }, [activeTemplateFilter, isBaseSize]);

  return (
    <SmartList
      items={templates as unknown as RegularOrWhatsappTemplate[]} // TODO: fix this
      itemIdentifier="id"
      containerRef={scrollContainerRef}
      columns={templateColumns}
      getIndividualActions={getIndividualActions}
    />
  );
};

export default TemplateList;
